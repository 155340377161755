html,
body,
#root {
    height: 100%;
    width: 100%;
}

#root {
    display: flex;
}
