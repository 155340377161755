@font-face {
    font-family: 'Alliance No.1';
    src: url('AllianceNo1-Regular.woff2') format('woff2'),
    url('AllianceNo1-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Alliance No.2';
    src: url('AllianceNo2-Regular.woff2') format('woff2'),
    url('AllianceNo2-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Avenir LT Std';
    src: url('AvenirLTStd-Medium.woff2') format('woff2'),
    url('AvenirLTStd-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Avenir Next LT Pro';
    src: url('AvenirNextLTPro-MediumCn.woff2') format('woff2'),
    url('AvenirNextLTPro-MediumCn.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Avenir Next LT Pro';
    src: url('AvenirNextLTPro-Regular.woff2') format('woff2'),
    url('AvenirNextLTPro-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Alliance No.1';
    src: url('AllianceNo1-Bold.woff2') format('woff2'),
    url('AllianceNo1-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

h1,
h2,
h3,
h4,
h5 {
    font-family: 'Alliance No.2';
    font-weight: normal;
}

p {
    font-family: 'Avenir Next LT Pro';
    font-weight: 400;
}
